<template>
    <div style="display: flex; flex-direction: column;">
        <div style="position: relative;">
            <img src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/%E6%B4%BB%E5%8A%A8%E8%A7%84%E5%88%9901.png" style="padding: 5px; width: 60px; height: auto; background-color: #ffc94f; border-radius: 10px 0 0 10px;position: absolute; top: 25px; right: 0px;" @click="ruleshow = true" />
            <img style="width: 100%; height: auto;vertical-align: middle;" src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file28.jpg" />
        </div>
        <div @click="openNewWindow('https://touch.dujia.qunar.com/p/detail?id=3495323798&date=2024-11-07&routeId=&tuId=&listDep=%E8%A5%BF%E5%AE%89&t3id=', '_blank')">
            <img style="width: 100%; height: auto;vertical-align: middle;" src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file27.jpg" />
        </div>
        <div @click="openNewWindow('https://wdgl3.package.qunar.com/user/detail.jsp?abt=a&id=4209194666&', '_blank')">
            <img style="width: 100%; height: auto;vertical-align: middle;" src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file26.jpg" />
        </div>
        <div @click="openNewWindow('https://rwxl3.package.qunar.com/user/detail.jsp?abt=a&id=375509572&', '_blank')">
            <img style="width: 100%; height: auto;vertical-align: middle;" src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file25.jpg" />
        </div>
        <div >
            <img style="width: 100%; height: auto;vertical-align: middle;" src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file24.jpg" />
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: flex-start; background: url('https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file21.jpg');background-size: 100% 100%;background-repeat: no-repeat; width: 100%; height: 175px;">
            <div style="margin-top: 10px;">
                <van-field
                        style="width: 220px;border-radius: 20px;border: 2px solid orange;"
                        v-model="cityinfo"
                        placeholder-class="centered-placeholder"
                        placeholder="请输入城市名称"
                />
                <!--
                @click="showareaPicker = true"
                -->
                <!--
                <van-popup v-model="showareaPicker" position="bottom">
                    <van-area title="城市选择" :columns-num="2" @cancel="showareaPicker = false" @confirm="selarea" :area-list="areaList" />
                </van-popup>
                -->
            </div>
            <div style=" margin-top: 10px;">
                <van-field
                        style="width: 220px;border-radius: 20px;border: 2px solid orange;"
                        v-model="userphone"
                        type="tel"
                        placeholder="请留下联系方式"
                />
            </div>
            <div @click="checkcjrec" style=" margin-top: 20px; width: 160px;height:43px; background: url('https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp09.png'); background-size: 100% 100%;">
            </div>
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; background: url('https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file22.jpg');background-size: 100% 100%;background-repeat: no-repeat; width: 100%; height: 460px;">
            <div class="wheel-container">
                <div class="wheel">
                    <!-- 转盘的6个区域内容 -->
                    <!--
                     backgroundColor: getBackgroundColor(index),
                     -->
                    <div
                            class="segment"
                            v-for="(prize, index) in prizes"
                            :key="index"
                            :style="{

          width: '95px',
          height: '50px',
          borderRadius: '50%',
          textAlign: 'center',
          lineHeight: '50px',
          fontSize: '10px',
          position: 'absolute',
          left: '50%',
          top: '50%',
          marginLeft: '-42.5px',
          marginTop: '-42.5px',
          transform: `rotate(${index * (360 / prizes.length)}deg) translate(90px) rotate(${-index * (360 / prizes.length)}deg)`
        }"
                    >
                        <img v-if="index === 0" style="margin-top: 20px; width: 80px;height: auto;" :src="prize.img_url" />
                        <img v-if="index === 3" style="margin-top: 20px; margin-left: -20px; width: 80px;height: auto;" :src="prize.img_url" />
                        <img v-if="index === 1" style="margin-top: 40px; margin-left: 0px; width: 80px;height: auto;" :src="prize.img_url" />
                        <img v-if="index === 2" style="margin-top: 40px; margin-left: -15px; width: 80px;height: auto;" :src="prize.img_url" />
                        <img v-if="index === 4" style="margin-top: 2px; width: 80px;height: auto;" :src="prize.img_url" />
                        <img v-if="index === 5" style="margin-top: 2px; width: 80px;height: auto;" :src="prize.img_url" />
                    </div>
                </div>
                <transition name="rotate-pointer">
                    <div class="pointer" :style="{transform: 'rotate(' + pointerRotation + 'deg)' }"></div>
                </transition>
            </div>
        </div>
        <div>
            <img style="width: 100%; height: auto;vertical-align: middle;" src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file23.jpg" />
        </div>
        <div @click="openNewWindow('https://touch.dujia.qunar.com/p/item/164723136', '_blank')">
            <img style="width: 100%; height: auto;vertical-align: middle;" src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file9.jpg" />
        </div>
        <div @click="openNewWindow('https://touch.dujia.qunar.com/p/item/3460414642', '_blank')">
            <img style="width: 100%; height: auto;vertical-align: middle;" src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file10.jpg" />
        </div>
        <div @click="openNewWindow('https://touch.dujia.qunar.com/p/item/4219915388', '_blank')">
            <img style="width: 100%; height: auto;vertical-align: middle;" src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file11.jpg" />
        </div>
        <div @click="openNewWindow('https://touch.dujia.qunar.com/p/item/4276275076', '_blank')">
            <img style="width: 100%; height: auto;vertical-align: middle;" src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file12.jpg" />
        </div>
        <div @click="openNewWindow('https://touch.dujia.qunar.com/p/item/3926290173', '_blank')">
            <img style="width: 100%; height: auto;vertical-align: middle;" src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file13.jpg" />
        </div>
        <div @click="openNewWindow('https://touch.dujia.qunar.com/p/item/3288182825', '_blank')">
            <img style="width: 100%; height: auto;vertical-align: middle;" src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file14.jpg" />
        </div>
        <div>
            <img style="width: 100%; height: auto;vertical-align: middle;" src="https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/file15.jpg" />
        </div>
        <van-dialog v-model="ruleshow" :show-confirm-button="false">
            <div style="height: 50px; background-color: transparent !important; display: flex; justify-content: flex-end; align-items: flex-end;">
                <img @click = "onruleclose" style="width:36px;height:36px;" src="https://aroll.oss-cn-beijing.aliyuncs.com/sc/%E5%85%B3%E9%97%AD.svg" />
            </div>
            <div style="width:320px;background-color: transparent; display: flex; flex-direction: column; align-items: center; margin-top: 5px;">
                <div style="height: 500px; width:320px; background-image:url('https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/%E6%B4%BB%E5%8A%A8%E8%A7%84%E5%88%9902.png'); background-size: 100% 100% !important; display: flex; flex-direction: column; align-items: center; margin-top: 5px;">
                    <div style="margin: 170px 26px 20px 26px; display: flex; flex-direction: column; font-size: 12px; height: 350px; overflow: auto;  scrollbar-gutter: stable;">
                        <div style="text-indent: 2ch;margin: 0px 10px 5px 0px;">本活动主办方为杭州顶津上海分公司，并委托去哪儿网平台客服（95117）提供售前、售后咨询服务。</div>
                        <div style="margin-top: 5px;">一、活动时间： 2024年11月8日00:00-12月7日23:59</div>
                        <div style="margin-top: 5px;">二、活动城市：全国 <br /> 参与活动之前请先登陆去哪儿网账户。 </div>
                        <div style="margin-top: 8px;"> 三、去哪儿都要无限·电抽奖活动</div>
                        <div style="margin-left: 5px; margin-top: 5px;">
                            （一）去哪儿都要无限·电抽取消费金 <br/>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">1.活动期间2024年11月8日00:00-12月7日23:59，用户通过填写城市及手机号，提交成功后随机赢取消费金； </div>
                            <div style="margin:5px 20px 5px 10px;">2.用户活动期内上传填写城市及手机号可获得1次抽奖机会；</div>
                            （二）兑奖部分 <br/>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">1.活动期间2024年11月8日00:00-12月7日23:59，中奖用户需填写真实姓名与身份证号、手机号（必须与去哪儿注册账号认证手机号码、中奖手机号一致）后可兑换领取奖品；</div>
                            <div style="margin:5px 20px 5px 10px;">2.活动期间，同一用户仅有1次兑奖机会。活动时间截止仍未进行兑换的中奖用户，将视为自动放弃，去哪儿网不再对用户补发奖品；</div>
                            （三）奖品设置 <br/>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">1.本次活动的奖品为三类，“去哪儿消费金”、 “康师傅无限·电购买优惠券一张”、“康师傅无限·电电解质水一瓶”。 </div>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">①“去哪儿消费金”，设立一等奖3名，每名中奖者获得去哪儿消费金1000元（含税，实得800元）；设立二等奖69名，每名中奖者获得去哪儿消费金500元（含税，实得400元）；设立三等奖2000名，每名中奖者获得去哪儿消费金5元（含税，实得4元）；中奖用户在填写完成真实姓名、身份证号、手机号后，消费金将于活动结束后七个工作日内发放至中奖用户的去哪儿账户；</div>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">②康师傅无限·电购买优惠券5元一张，共1000名；中奖用户点击中奖弹窗下方“点击领取购买”按钮即可领取到抖音商城价值5元的康师傅无限·电 电解质水优惠券，进入商城可用于抵扣康师傅无限·电 电解质水。此券仅限抖音平台康师傅官方旗舰店售卖的店铺指定商品（无限·电 电解质水）可使用，券有效期为2024年11月8日00:00:00-2024年12月8日23:59:59（本活动与抖音无任何合作关系，仅为了用户可以在抖音平台中商城使用优惠券）；</div>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">③康师傅无限·电电解质水1瓶，共1000名，中奖用户需填写收件人真实姓名、手机号、联系地址，提交后不得修改，请用户谨慎填写提交。实体奖品将根据用户填写信息在七个工作日内发出。截止2024年12月7日23:59之后未填写真实有效的中奖用户信息兑换领取奖品的，视为用户放弃，主办方不再对其进行补发；</div>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">2.关于消费金的使用 </div>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">①获得“去哪儿都要无限·电消费金”奖品的用户，需为去哪儿网注册用户（未注册用户需要去哪儿网账号），去哪儿网账户异常状态（注销、冻结）将无法进行发放。</div>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">②“去哪儿都要无限·电消费金”奖品将以“消费金”形式发放至去哪儿网用户账户，不可提现，发放成功后，使用时间无限制。</div>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">③获奖用户在去哪儿网客户端“我的-余额-消费金”可查看到消费金具体金额及使用后余额。</div>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">④“消费金”可用于去哪儿网全品类产品使用，但仅限于去哪儿网APP端及网页端进行支付的产品订单，如在12306火车票官网直购，或在微信小程序上购买产品等则不能使用消费金，请消费用户在决定购买产品前，仔细查看说明/支付要求。</div>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">⑤如遇到使用问题，可拨打去哪儿网客服电话：95117</div>
                            （四）其他说明： <br/>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">1.同一用户账号、手机号、证件号、终端设备号、银行卡号、第三方支付账号，符合以上任一条件均视为同一用户；用户重复使用上述用户信息和重复领取的属于重复操作，仅最先领取的用户账号享有奖品获取资格，其他重复账户不享有奖品获取资格，已违规领取的将无法使用或被注销处理；</div>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">2.主办方可根据活动的实际举办情况对活动规则进行变更或调整，相关变动或调整将公布在活动页面或以合适的方式及时通知；</div>
                            <div style="margin-left: 10px;margin-top: 5px; margin-right: 20px;">3.如遇不可抗力（包括但不限于重大灾害事件、活动受政府机关指令需要停止举办或调整的、活动中存在大面积作弊行为、活动遭受严重网络攻击或因系统故障，活动不能正常进行的），主办方可取消或暂停本活动；</div>
                            <div style="margin-left: 10px;margin-top: 5px; margin-bottom: 10px; margin-right: 20px;">4.参与活动过程中，如用户存在任何违法或违反诚实信用原则的行为（包括不限于作弊、洗钱、套现、违规账号、批量注册、使用销号/空号/停机号/虚拟号码注册注册、用户机器注册账户、虚假交易、扰乱系统、实施网络攻击、兜售倒卖、转让所获得的奖励/权益等），主办方或去哪儿网有权取消其活动参与资格及领取奖励资格，已发放的奖励，主办方有权予以收回，同时保留追究其法律责任的权利。</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-dialog>
        <van-dialog v-model="addcjshow" :show-confirm-button="false">
            <div style="height: 50px; background-color: transparent !important; display: flex; justify-content: flex-end; align-items: flex-end;">
                <img @click = "oncjclose" style="width:36px;height:36px;" src="https://aroll.oss-cn-beijing.aliyuncs.com/sc/%E5%85%B3%E9%97%AD.svg" />
            </div>
            <div v-if="giftvalue === '1'" style="width:320px; background-color: transparent !important; display: flex; flex-direction: column; align-items: center; ">
                <div style="height: 440px; width:320px; background-image:url('https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/%E5%BC%B9%E7%AA%9701_%E9%A5%AE%E6%96%991%E7%93%B6.png'); background-size: 100% auto !important; display: flex; flex-direction: column; align-items: center; ">
                    <div style="margin-top: 240px;">
                        <van-field
                                style="width: 280px;border-radius: 20px;border: 1px solid orange;"
                                v-model="username"
                                placeholder-class="centered-placeholder"
                                placeholder="请输入您的姓名"
                        />
                    </div>
                    <div style=" margin-top: 6px;">
                        <van-field
                                style="width: 280px;border-radius: 20px;border: 1px solid orange;"
                                v-model="userphone"
                                type="tel"
                                placeholder="请输入您的电话"
                        />
                    </div>
                    <div style=" margin-top: 6px;">
                        <van-field
                                style="width: 280px;border-radius: 20px;border: 1px solid orange;"
                                v-model="useraddress"
                                placeholder="请输入您的地址">
                        </van-field>
                    </div>
                </div>
                <div @click="addcjrec(giftvalue,1)" style="width: 320px; height: 40px; display: flex; align-items: center; justify-content: center; border-radius: 20px; margin-top: 20px; background-color:#ff892f; color: #fff;  margin-bottom: 20px;">
                    提交获奖数据
                </div>
            </div>
            <div v-if="giftvalue === '6'" style="width:320px;background-color: transparent; display: flex; flex-direction: column; align-items: center; margin-top: 5px;">
                <div style="height: 260px; width:320px; background-image:url('https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/%E5%BC%B9%E7%AA%9706_%E6%9C%AA%E4%B8%AD%E5%A5%96.png'); background-size: 100% 100% !important; display: flex; flex-direction: column; align-items: center; margin-top: 5px;">
                </div>
            </div>
            <div v-if="giftvalue === '2'" style="width:320px;background-color: transparent !important; display: flex; flex-direction: column; align-items: center; margin-top: 5px; width: 100%;min-height: 200px;">
                <div style="height: 260px; width:320px; background-image:url('https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/%E5%BC%B9%E7%AA%9702_5%E5%85%83%E4%BB%A3%E9%87%91%E5%88%B8.png'); background-size: 100% auto !important; display: flex; flex-direction: column; align-items: center; margin-top: 5px; ">
                </div>
                <div @click="addcjrec(giftvalue,5)" style="width: 320px; height: 40px; display: flex; align-items: center; justify-content: center; border-radius: 20px; margin-top: 20px; background-color:#ff892f; color: #fff;  margin-bottom: 20px;">
                    点击领取购买
                </div>
            </div>
            <div v-if="giftvalue === '3'" style="width:320px;background-color:transparent !important; display: flex; flex-direction: column; align-items: center; ">
                <div style="height: 438px; width:320px; background-image:url('https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/%E5%BC%B9%E7%AA%9705_%E6%B6%88%E8%B4%B9%E9%87%915.png'); background-size: 100% auto !important; display: flex; flex-direction: column; align-items: center;">
                    <div style="margin-top: 206px;">
                        <van-field
                                style="width: 280px;border-radius: 20px;border: 2px solid orange;"
                                v-model="username"
                                placeholder-class="centered-placeholder"
                                placeholder="请输入您的姓名"
                        />
                    </div>
                    <div style=" margin-top: 4px;">
                        <van-field
                                style="width: 280px;border-radius: 20px;border: 2px solid orange;"
                                v-model="userphone"
                                type="tel"
                                placeholder="请输入您的电话"
                        />
                    </div>
                    <div style=" margin-top: 4px;">
                        <van-field
                                style="width: 280px;border-radius: 20px;border: 2px solid orange;"
                                v-model="sfzno"
                                placeholder="请输入身份证号码">
                        </van-field>
                    </div>
                </div>
                <div @click="addcjrec(giftvalue,5)" style="width: 320px; height: 40px; display: flex; align-items: center; justify-content: center; border-radius: 20px; margin-top: 20px; background-color:#ff892f; color: #fff;  margin-bottom: 20px;">
                    提交获奖信息
                </div>
            </div>
            <div v-if="giftvalue === '4'" style="width:320px;background-color:transparent !important; display: flex; flex-direction: column; align-items: center; margin-top: 5px; ">
                <div style="width:320px; height: 438px; background-image:url('https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/%E5%BC%B9%E7%AA%9704_%E6%B6%88%E8%B4%B9%E9%87%91500.png'); background-size: 100% auto !important; display: flex; flex-direction: column; align-items: center; margin-top: 5px;">
                    <div style="margin-top: 206px;">
                        <van-field
                                style="width: 280px;border-radius: 20px;border: 2px solid orange;"
                                v-model="username"
                                placeholder-class="centered-placeholder"
                                placeholder="请输入您的姓名"
                        />
                    </div>
                    <div style=" margin-top: 4px;">
                        <van-field
                                style="width: 280px;border-radius: 20px;border: 2px solid orange;"
                                v-model="userphone"
                                type="tel"
                                placeholder="请输入您的电话"
                        />
                    </div>
                    <div style=" margin-top: 4px;">
                        <van-field
                                style="width: 280px;border-radius: 20px;border: 2px solid orange;"
                                v-model="sfzno"
                                placeholder="请输入身份证号码">
                        </van-field>
                    </div>
                </div>
                <div @click="addcjrec(giftvalue,500)" style="width: 320px; height: 40px; display: flex; align-items: center; justify-content: center; border-radius: 20px; margin-top: 20px; background-color:#ff892f; color: #fff;  margin-bottom: 20px;">
                    提交获奖信息
                </div>
            </div>
            <div v-if="giftvalue === '5'" style="width:320px;background-color:transparent !important; display: flex; flex-direction: column; align-items: center; margin-top: 5px; min-height: 200px;">
                <div style="height: 438px;width:320px;background-repeat: no-repeat; background-position: center; background-image:url('https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/%E5%BC%B9%E7%AA%9703_%E6%B6%88%E8%B4%B9%E9%87%911000.png'); background-size: 100% auto !important; display: flex; flex-direction: column; align-items: center; margin-top: 5px; ">
                    <div style="margin-top: 206px;">
                        <van-field
                                style="width: 280px;border-radius: 20px;border: 2px solid orange;"
                                v-model="username"
                                placeholder-class="centered-placeholder"
                                placeholder="请输入您的姓名"
                        />
                    </div>
                    <div style=" margin-top: 4px;">
                        <van-field
                                style="width: 280px;border-radius: 20px;border: 2px solid orange;"
                                v-model="userphone"
                                type="tel"
                                placeholder="请输入您的电话"
                        />
                    </div>
                    <div style=" margin-top: 4px;">
                        <van-field
                                style="width: 280px;border-radius: 20px;border: 2px solid orange;"
                                v-model="sfzno"
                                placeholder="请输入身份证号码">
                        </van-field>
                    </div>
                </div>
                <div @click="addcjrec(giftvalue,1000)" style="width: 320px; height: 40px; display: flex; align-items: center; justify-content: center; border-radius: 20px; margin-top: 20px; background-color:#ff892f; color: #fff;  margin-bottom: 20px;">
                    提交获奖信息
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Notify } from 'vant';
    import { Popup } from 'vant';
    import { Area } from 'vant';
    import wx from 'weixin-js-sdk'
    import { usePush } from 'vue-baidu-analytics'
    import axios from 'axios';
    import { Dialog } from 'vant';
    import Utils from '../util/utils'
    Vue.use(Dialog);
    Vue.use(Notify);
    Vue.use(Field);
    Vue.use(Popup);
    Vue.use(Area);
    Vue.use(Form);
    Vue.prototype.$http = axios;
    var that
    var wxinfo = wx
    export default {
        name: "wuxiandian",
        data() {
            return {
                baidutj: usePush(),
                accesstoken: '',
                cityinfo: '',
                ruleshow: false,
                userphone: '',
                username: '',
                showareaPicker: false,
                neterror: false, // 首付网络错误
                ifallowcj: false, // 是否允许抽奖
                addcjshow: false,
                giftvalue: '',
                prizes: [
                    { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                    { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 1 },
                    { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0},
                    { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                    { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                    { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0 }
                ],
                pointerRotation: 0,
                giftinfo: '',
                useraddress: '',
                sfzno: '',
                isSpinning: false,
                currentResult: null,
                result1: 0,
                result2: 0,
                result3: 0,
                result4: 0,
                result5: 0,
                result6: 0,
                result7: 0,
                result8: 0,
                result9: 0,
                result10: 0,
                ifrev: false
            }
        },
        mounted: function () {
            that = this
            document.title = '去哪儿都要无限·电'
            window.console.log(that)
            // that.getaccesstoken()
            that.checkcjreccount()
            that.checkdaycjreccount()
            that.initprobability()
            var curDate = Utils.dateFormat(new Date(), 'yyyy/MM/dd')
            var compareDate = ''
            compareDate = Utils.dateFormat(new Date('2024/11/08'), 'yyyy/MM/dd')
            window.console.log('当天日期',curDate === compareDate)
        },
        methods: {
            addrecount (gnid) {
                var curDate = Utils.dateFormat(new Date(), 'yyyy/MM/dd')
                var datatmp = {
                    gnid: gnid.toString(),
                    adddate: curDate
                }
                that.$http({
                    url: 'https://api.dongnanmaifeng.com/upreccount',
                    method: 'post',
                    data: datatmp,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            selarea (e) {
                window.console.log(e)
                var addresstmp = ''
                addresstmp = e[0].name
                this.cityinfo = addresstmp
                this.showareaPicker = false
            },
            initprobability () {
                var curDate = Utils.dateFormat(new Date(), 'yyyy/MM/dd')
                var compareDate = ''
                compareDate = Utils.dateFormat(new Date('2024/11/08'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0.05 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0.83 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.05 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0.03 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0.02 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.02 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/09'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0.05 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0.83 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.05 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0.03 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.04 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/10'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0.6 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.4 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/11'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0.6 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.4 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/12'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0.05 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0.83 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.05 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0.03 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0.02 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.02 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/13'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0.05 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0.83 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.05 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0.03 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0.02 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.02 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/14'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0.83 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.05 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0.03 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.09 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/15'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0.83 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.05 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0.03 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.09 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/16'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0.83 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.05 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0.03 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.09 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/17'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.6 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0.03 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.37 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/18'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0. },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0.83 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.05 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0.03 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.09 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/19'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.6 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.37 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/20'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0.83 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.05 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.09 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/21'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.6 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.4 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/22'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.6 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.4 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/23'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.6 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.4 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/24'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.6 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.4 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/25'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0.83 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.05 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.09 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/26'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.6 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.37 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/27'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.6 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.4 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/28'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.6 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.4 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/29'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.6 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.37 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/11/30'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.6 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.37 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/12/01'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0.6 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 0.4 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/12/02'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 1 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/12/03'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 1 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/12/04'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 1 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/12/05'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 1 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/12/06'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 1 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/12/07'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 1 }
                    ]
                }
                compareDate = Utils.dateFormat(new Date('2024/12/08'), 'yyyy/MM/dd')
                if (curDate === compareDate) {
                    that.prizes = [
                        { name: '1瓶无限·电电解质水',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp03.png',value:'1', probability: 0 },
                        { name: '5元无限·电购买优惠券',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp04.png',value:'2', probability: 0 },
                        { name: '5元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp05.png',value:'3', probability: 0 },
                        { name: '500元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp07.png',value:'4', probability: 0 },
                        { name: '1000元消费金',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp06.png',value:'5', probability: 0 },
                        { name: '未中奖,谢谢参与',img_url: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp081.png',value:'6', probability: 1 }
                    ]
                }
                window.console.log('当前规则',that.prizes)
            },
            async checkcjreccount () {
                var qstr = {
                    fromparms: ' CAST(count(*) AS SIGNED) as reccount,cjtype',
                    whereinfo: " userphone is not null",
                    limit: '',
                    sortinfo: ' group by cjtype'
                }
                let response = that.$http({
                    url: 'https://api.dongnanmaifeng.com/queryusergiftcount',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('统计',res.data)
                        if (res.data.length > 0) {
                            for (var i = 0; i < res.data.length; i++) {
                                if (parseInt(res.data[i].cjtype) === 1) {
                                    that.result1 = res.data[i].reccount
                                }
                                if (parseInt(res.data[i].cjtype) === 2) {
                                    that.result2 = res.data[i].reccount
                                }
                                if (parseInt(res.data[i].cjtype) === 3) {
                                    that.result3 = res.data[i].reccount
                                }
                                if (parseInt(res.data[i].cjtype) === 4) {
                                    that.result4 = res.data[i].reccount
                                }
                                if (parseInt(res.data[i].cjtype) === 5) {
                                    that.result5 = res.data[i].reccount
                                }
                            }
                            window.console.log('result1',that.result1)
                            window.console.log('result2',that.result2)
                            window.console.log('result3',that.result3)
                            window.console.log('result4',that.result4)
                            window.console.log('result5',that.result5)
                        } else {
                            that.result1 = 0
                            that.result2 = 0
                            that.result3 = 0
                            that.result4 = 0
                            that.result5 = 0
                            window.console.log('result1',that.result1)
                            window.console.log('result2',that.result2)
                            window.console.log('result3',that.result3)
                            window.console.log('result4',that.result4)
                            window.console.log('result5',that.result5)
                        }
                    })
                    .catch(function (error) {
                        that.ifallowcj = false
                        that.result1 = 0
                        that.result2 = 0
                        that.result3 = 0
                        that.result4 = 0
                        that.result5 = 0
                        window.console.log(error)
                    })
                return response
            },
            async checkdaycjreccount () {
                let curdate = Utils.dateFormat(new Date(), 'yyyy-MM-dd') // 当前时间
                var qstr = {
                    fromparms: ' CAST(count(*) AS SIGNED) as reccount,cjtype',
                    whereinfo: " userphone is not null and adddate > '" + curdate + " 00:00:00' and adddate < '" + curdate + " 23:59:59'",
                    limit: '',
                    sortinfo: ' group by cjtype'
                }
                let response = that.$http({
                    url: 'https://api.dongnanmaifeng.com/queryusergiftcount',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('统计',res.data)
                        if (res.data.length > 0) {
                            for (var i = 0; i < res.data.length; i++) {
                                if (parseInt(res.data[i].cjtype) === 1) {
                                    that.result6 = res.data[i].reccount
                                }
                                if (parseInt(res.data[i].cjtype) === 2) {
                                    that.result7 = res.data[i].reccount
                                }
                                if (parseInt(res.data[i].cjtype) === 3) {
                                    that.result8 = res.data[i].reccount
                                }
                                if (parseInt(res.data[i].cjtype) === 4) {
                                    that.result9 = res.data[i].reccount
                                }
                                if (parseInt(res.data[i].cjtype) === 5) {
                                    that.result10 = res.data[i].reccount
                                }
                            }
                            window.console.log('result6',that.result6)
                            window.console.log('result7',that.result7)
                            window.console.log('result8',that.result8)
                            window.console.log('result9',that.result9)
                            window.console.log('result10',that.result10)
                        } else {
                            that.result6 = 0
                            that.result7 = 0
                            that.result8 = 0
                            that.result9 = 0
                            that.result10 = 0
                            window.console.log('result6',that.result6)
                            window.console.log('result7',that.result7)
                            window.console.log('result8',that.result8)
                            window.console.log('result9',that.result9)
                            window.console.log('result10',that.result10)
                        }
                    })
                    .catch(function (error) {
                        that.ifallowcj = false
                        that.result1 = 0
                        that.result2 = 0
                        that.result3 = 0
                        that.result4 = 0
                        that.result5 = 0
                        window.console.log(error)
                    })
                return response
            },
            oncjclose () {
                this.addcjshow = false
            },
            onruleclose () {
                this.ruleshow = false
            },
            startCJ () {
                if (!isValidPhoneNumber(this.userphone)) {
                    Notify({ type: 'danger', message: '请检查您的手机号输入' });
                    return
                }
                if (this.cityinfo === '') {
                    Notify({ type: 'danger', message: '信息输入不完整' });
                    return;
                }
                if (localStorage.getItem('alreadyprize') !== null && localStorage.getItem('alreadyprize') !== undefined && parseInt(localStorage.getItem('alreadyprize')) === 1) {
                    Notify({ type: 'danger', message: '每一人只能抽取一次' });
                    return
                }
                if (!this.ifallowcj) {
                    Notify({ type: 'danger', message: '您无法再次抽奖' });
                    return
                }
                localStorage.setItem('cityinfo', that.cityinfo)
                localStorage.setItem('userphone', that.userphone)
                this.spinPointer()
            },
            addcjrec (cjtype,numinfo) {
                window.console.log(this.sfzno)
                window.console.log(validateIdNumber(this.sfzno))
                this.baidutj.event('button', 'click', '领取')
                this.addrecount('2')
                /* 奖品1 数量 50，超过50就没库存了 */
                if (parseInt(cjtype) === 1) {
                    if (that.result1 > 50) {
                        Notify({ type: 'danger', message: '抱歉，没有库存了' });
                        return;
                    }
                }
                /* 奖品2 数量 1000，超过1000就没库存了 */
                if (parseInt(cjtype) === 2) {
                    var curDate = Utils.dateFormat(new Date(), 'yyyy/MM/dd')
                    var compareDate1 = ''
                    compareDate1 = Utils.dateFormat(new Date('2024/11/20'), 'yyyy/MM/dd')
                    var compareDate2 = ''
                    compareDate2 = Utils.dateFormat(new Date('2024/11/25'), 'yyyy/MM/dd')
                    if (that.result7 > 50 && (curDate === compareDate1 || curDate === compareDate2)) {
                        Notify({ type: 'danger', message: '抱歉，当日没有库存了' });
                        return;
                    }
                    if (that.result2 > 1000) {
                        Notify({ type: 'danger', message: '抱歉，没有库存了' });
                        return;
                    }
                }
                /* 奖品3 数量 2000，超过2000就没库存了 */
                if (parseInt(cjtype) === 3) {
                    if (that.result8 > 50) {
                        Notify({ type: 'danger', message: '抱歉，当日没有库存了' });
                        return;
                    }
                    if (that.result3 > 2000) {
                        Notify({ type: 'danger', message: '抱歉，没有库存了' });
                        return;
                    }
                }
                /* 奖品4 数量 500，超过500就没库存了 */
                if (parseInt(cjtype) === 4) {
                    if (that.result4 > 69) {
                        Notify({ type: 'danger', message: '抱歉，没有库存了' });
                        return;
                    }
                }
                /* 奖品5 数量 500，超过500就没库存了 */
                if (parseInt(cjtype) === 5) {
                    if (that.result5 > 3) {
                        Notify({ type: 'danger', message: '抱歉，没有库存了' });
                        return;
                    }
                }
                if (parseInt(cjtype) >= 3 && parseInt(cjtype) < 6) {
                    if (!validateIdNumber(this.sfzno)) {
                        Notify({ type: 'danger', message: '请检查您的身份证输入' });
                        return
                    }
                }
                if (localStorage.getItem('alreadylq') !== null && localStorage.getItem('alreadylq') !== undefined && parseInt(localStorage.getItem('alreadylq')) === 1) {
                    Notify({ type: 'danger', message: '每一人只能领取一次' });
                    return
                } else {
                    localStorage.setItem('alreadylq', 1)
                }
                /*
                if (!this.ifallowcj) {
                    Notify({ type: 'danger', message: '您无法再次抽奖' });
                    return
                }*/

                let addtime = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                var datatmp = {
                    userphone: this.userphone,
                    city: this.cityinfo,
                    username: this.username,
                    gift: this.giftinfo,
                    adddate: addtime,
                    cjtype: cjtype.toString(),
                    giftnum: numinfo.toString(),
                    address: this.useraddress,
                    sfzno: this.sfzno
                }
                that.$http({
                    url: 'https://api.dongnanmaifeng.com/addusergift',
                    method: 'post',
                    data: datatmp,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            if (parseInt(cjtype) < 6) {
                                Notify({ type: 'success', message: '领取成功' });
                                that.addcjshow = false
                            }
                            if (parseInt(cjtype) === 2) {
                                that.addcjshow = false
                                window.location.href = 'https://d.opsai.cn/HhHfnz'
                            }
                        } else {
                            Notify({ type: 'danger', message: '领取失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            checkcjrec () {
                this.baidutj.event('button', 'click', '抽奖')
                this.addrecount('1')
                var qstr = {
                    whereinfo: " userphone='" + that.userphone + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://api.dongnanmaifeng.com/queryusergift',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('该手机存在记录',res.data)
                        if (res.data.length > 0) {
                            that.ifallowcj = false
                        } else {
                            that.ifallowcj = true
                        }
                        that.startCJ()
                    })
                    .catch(function (error) {
                        that.ifallowcj = false
                        window.console.log(error)
                    })
            },
            spinPointer() {
                if (!this.isSpinning) {
                    this.isSpinning = true;
                    // 根据抽奖比率生成随机数来确定中奖结果
                    let randomNum = Math.random();
                    let cumulativeProbability = 0;
                    for (let i = 0; i < this.prizes.length; i++) {
                        cumulativeProbability += this.prizes[i].probability;
                        if (randomNum < cumulativeProbability) {
                            this.currentResult = this.prizes[i];
                            break;
                        }
                    }
                    // 计算指针最终的旋转角度
                    const targetRotation = this.getTargetRotation(this.currentResult);
                    // 设置指针旋转动画
                    this.$nextTick(() => {
                        const duration = 1.5; // 指针旋转动画持续时间，单位：秒
                        const ease = 'easeOutCubic'; // 动画缓动效果
                        window.console.log(ease)
                        const self = this;
                        let startTime = null;

                        function step(timestamp) {
                            if (!startTime) startTime = timestamp;
                            const progress = (timestamp - startTime) / (duration * 1000);
                            if (progress < 1) {
                                self.pointerRotation = targetRotation * progress;
                                requestAnimationFrame(step);
                            } else {
                                self.pointerRotation = targetRotation;
                                self.isSpinning = false;
                                window.console.log('中奖结果：', self.currentResult.name);
                                that.giftinfo = self.currentResult.name
                                that.giftvalue = self.currentResult.value
                                if (parseInt(that.giftvalue) === 6) {
                                    that.addcjrec(that.giftvalue,0)
                                }
                                that.addcjshow = true
                                localStorage.setItem('alreadyprize',1)
                            }
                        }
                        requestAnimationFrame(step);
                    });
                }
            },
            getTargetRotation(result) {
                const segmentIndex = this.prizes.findIndex((prize) => prize === result);
                const segmentAngle = 360 / this.prizes.length;
                const baseRotation = segmentIndex * segmentAngle;
                const randomOffset = Math.floor(Math.random() * segmentAngle);
                return baseRotation + randomOffset + 60;
            },
            getBackgroundColor(index) {
                const colors = ['#FF5733', '#33FF57', '#5733FF', '#FF33E1', '#33E1FF', '#E1FF33'];
                return colors[index];
            },
            configwx: function () {
                // 获取微信签名
                var url = window.location.href;
                this.$http({
                    url: 'https://wx.dongnanmaifeng.com/wx/getwxticket',
                    method: 'post',
                    data: {
                        accesstoken: that.accesstoken,
                        url: url
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.status === 200) {
                            var tmpdata = res.data
                            window.console.log(tmpdata)
                            var sha1sign = tmpdata.sign
                            var timestamp = tmpdata.timestamp
                            var noncestr = tmpdata.noncestr
                            wxinfo.config({
                                beta: false, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
                                appId: 'wx530360f6aa2990a5', //填，企业微信的corpID
                                timestamp: timestamp, // 必填，生成签名的时间戳
                                nonceStr: noncestr, // 必填，生成签名的随机串 必填，生成签名的随机串
                                signature: sha1sign, // 必填，签名
                                jsApiList: [
                                    'onMenuShareAppMessage',
                                    'onMenuShareTimeline'
                                ]
                            })
                            wxinfo.ready(function () {
                                wxinfo.onMenuShareAppMessage({
                                    title: '去哪儿都要无限·电', // 分享标题
                                    desc: '金秋出游为你补水补电', // 分享描述
                                    link: 'https://app.dongnanmaifeng.com/#/wuxiandian', // 分享链接
                                    imgUrl: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20241104173605.jpg', // 分享图标
                                    type: 'link', // 分享类型,music、video或link，不填默认为link
                                    dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                                    success: function () {
                                        // 用户确认分享后执行的回调函数
                                    },
                                    cancel: function () {
                                        // 用户取消分享后执行的回调函数
                                    }
                                })
                            });
                            window.console.log(res)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            async getaccesstoken () {
                let response = await that.$http({
                    url: 'https://wx.dongnanmaifeng.com/wx/getaccesstoken',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data !== '') {
                            localStorage.setItem('accesstoken',res.data)
                            that.accesstoken = res.data
                            that.configwx()

                            // 把相关数据缓存到本地
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
                return  response
            },
            goclick () {
                window.console.log(this.baidutj)
                this.baidutj.pv('http://app.dongnanmaifeng.com');
            },
            openNewWindow(url, windowName) {
                // 这里的windowName可以是'_blank'，表示在新标签页中打开链接
                this.addrecount(url)
                window.open(url, windowName);
            }
        }
    }
    function isValidPhoneNumber(phoneNumber) {
        return /^1[3-9]\d{9}$/.test(phoneNumber);
    }
    function validateIdNumber(id) {
        if (!id || id.length !== 18) return false;
        const city = { 11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏", 61: "陕西", 62: "甘肃", 63: "甘肃", 64: "青海", 65: "宁夏", 71: "新疆", 81: "香港", 82: "澳门", 91: "国外" };
        if (city[id.substring(0, 2)] === undefined) return false;
        const birth = id.substring(6, 14);
        if (Number(birth.substring(0, 4)) > 2021 || Number(birth.substring(4, 6)) > 12) return false;
        const sum = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        const checkCode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
        let s = 0;
        for (let i = 0; i < 17; i++) {
            s += id.charAt(i) * sum[i];
        }
        if (checkCode[s % 11] !== id.charAt(17).toUpperCase()) return false;
        return true;
    }
</script>

<style scope>
    .van-dialog {
        background-color: transparent;
    }
    .van-field__control::-webkit-input-placeholder {
        color: #666666;
        font-size: 18px;
        text-align: center;
        font-weight: 600;
    }
    .wheel-container {
        position: relative;
        width: 320px;
        height: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .wheel {
        width: 320px;
        height: 320px;
        border-radius: 50%;
        background: url("https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp01.png");
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .pointer {
        position: absolute;
        width: 44px;
        height: 130px;
        top: 90px;
        left: 130px;
        background: url('https://sqpro.oss-cn-beijing.aliyuncs.com/dongapp/zp02.png');
        background-size: 100% 100%;
        border-radius: 2px;
        z-index: 1;
    }

    /* 指针旋转动画样式 */
    .rotate-pointer-enter-active,
    .rotate-pointer-leave-active {
        transition: transform 3s easeOutCubic;
    }

    .rotate-pointer-enter,
    .rotate-pointer-leave-to {
        transform: rotate(0deg);
    }

    .rotate-pointer-enter-to,
    .rotate-pointer-leave {
        transform: rotate(360deg);
    }

    button {
        margin-top: 15px;
        padding: 8px 16px;
        background-color: #007BFF;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }



</style>